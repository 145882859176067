import debug from 'debug';

const BASE_CONTEXT = 'moodbit-dashboard';

const COLOURS = {
  trace: 'green',
  info: 'blue',
  warn: 'orange',
  error: 'red',
  debug: 'pink'
};

export default class Logger {
  static generateMessage(level, message, source) {
    const env = process.env.NODE_ENV;

    if (env !== 'production') {
      if (!localStorage.getItem('debug')) {
        localStorage.setItem('debug', `${BASE_CONTEXT}:*`);
      }
      // Set the prefix which will cause debug to enable the message
      const namespace = `${BASE_CONTEXT}:${level}`;
      const createDebug = debug(namespace);

      // Set the colour of the message based on the level
      createDebug.color = COLOURS[level];
      if (source) {
        createDebug(message, source);
      } else {
        createDebug(message);
      }
    }
  }

  static trace(message, source) {
    return Logger.generateMessage('trace', message, source);
  }

  static info(message, source) {
    return Logger.generateMessage('info', message, source);
  }

  static warn(message, source) {
    return Logger.generateMessage('warn', message, source);
  }

  static error(message, source) {
    return Logger.generateMessage('error', message, source);
  }

  static debug(message, source) {
    return Logger.generateMessage('debug', message, source);
  }
}
