import types from './types';
import Logger from '../../../../../../helpers/logger';

const success = (state, action) => {
  if (action.type !== types.success) {
    return state;
  }
  Logger.info('Main Data Loaded');
  return { loaded: true, data: action.payload };
};

const fail = (state, action) => {
  if (action.type !== types.fail) {
    return state;
  }
  Logger.warn('Main Data Failed To Load');
  return { loaded: false, data: [], error: action.payload };
};

const reducers = {
  success,
  fail
};

export default reducers;
