import React from 'react';
import Loader from 'react-loader';

import {
  Button,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Table,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

import Uid from '../../../helpers/uid';

const AssingUsersView = ({ stripe, handleChange, workspaces, addSubs }) => (
  <Card>
    <CardHeader>
      <CardTitle style={{ display: 'inline-block' }} tag="h5">
        Assign Users
      </CardTitle>{' '}
    </CardHeader>
    <CardBody>
      {stripe.stats && stripe.stats.available > 0 ? (
        <Table responsive>
          <thead className="text-primary">
            <tr>
              <th key={Uid()} className="text-left">
                Name
              </th>
              <th key={Uid()} className="text-left">
                Email
              </th>

              <th key={Uid()} className="text-left">
                Workspace
              </th>

              <th key={Uid()} className="text-center" />
            </tr>
          </thead>
          <tbody>
            <tr key={Uid()}>
              <td>
                <Input
                  type="textfield"
                  name="assignedUserName"
                  placeholder="Joy Bot"
                  onChange={handleChange}
                />
              </td>
              <td>
                <Input
                  type="email"
                  name="assignedUserEmail"
                  placeholder="joy@mymoodbit.com"
                  onChange={handleChange}
                />
              </td>
              <td>
                <Input
                  type="select"
                  name="assignedUserWorkspace"
                  id="assignedUserWorkspace"
                  // onChange={handleChange}
                >
                  {workspaces.data.map(item => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Input>
              </td>
              <td>
                <Button color="primary" onClick={addSubs}>
                  Assign
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        'No Slots Left to Assign'
      )}
    </CardBody>
  </Card>
);

const AssignedUsersView = ({
  used,
  user,
  toggleRemoveAdvanced,
  show,
  removeSubs,
  advanced
}) => (
  <Card>
    <CardHeader>
      <CardTitle style={{ display: 'inline-block' }} tag="h5">
        Assigned Users
      </CardTitle>{' '}
    </CardHeader>
    <CardBody>
      {used && used.length > 0 ? (
        <Table responsive>
          <thead className="text-primary">
            <tr>
              <th key={Uid()} className="text-left">
                Name
              </th>
              <th key={Uid()} className="text-left">
                Email
              </th>

              <th key={Uid()} className="text-left">
                Workspace
              </th>

              <th key={Uid()} className="text-center" />
            </tr>
          </thead>
          <tbody>
            {used
              ? used.map(item => (
                  <tr key={Uid()}>
                    <td>{item.child.name}</td>
                    <td>{item.child.email}</td>
                    <td>{item.team.name}</td>
                    <td>
                      {item.parent.email === user.email ? (
                        <div key={Uid()}>
                          <Button
                            key={Uid()}
                            color="danger"
                            onClick={() => toggleRemoveAdvanced(item)}
                          >
                            Remove
                          </Button>
                          <Modal
                            isOpen={
                              show === `${item.team.name}/${item.child.email}`
                            }
                            key={Uid()}
                          >
                            <ModalHeader> Remove Subscription</ModalHeader>
                            <ModalBody>
                              <strong>WARNING</strong>: This will{' '}
                              <strong>
                                remove {item.child.email} ({item.team.name})
                              </strong>
                              &nbsp; from your subscriptions. This user will
                              lose access to enchanced mood tracking and
                              analytics. Are you sure?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="primary"
                                onClick={() => {
                                  removeSubs(advanced, item);
                                  toggleRemoveAdvanced({
                                    child: { email: '' },
                                    team: { name: '' }
                                  });
                                }}
                              >
                                Confirm
                              </Button>
                              <Button
                                key={Uid()}
                                color="secondary"
                                onClick={() =>
                                  toggleRemoveAdvanced({
                                    child: { email: '' },
                                    team: { name: '' }
                                  })
                                }
                              >
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      ) : (
        'No Assigned Users'
      )}
    </CardBody>
  </Card>
);

const SubscriptionsView = ({ stripe }) => (
  <Card>
    <CardHeader>
      <CardTitle style={{ display: 'inline-block' }} tag="h5">
        Subscriptions
      </CardTitle>{' '}
      <a className="btn btn-success" size="sm" href="/subscriptions">
        Buy More
      </a>
    </CardHeader>
    <CardBody>
      <Table responsive>
        <thead className="text-primary">
          <tr>
            <th key={Uid()} className="text-left">
              Type
            </th>
            <th key={Uid()} className="text-left">
              Total Slots
            </th>

            <th key={Uid()} className="text-left">
              Slots Used
            </th>

            <th key={Uid()} className="text-left">
              Slots Available
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key={Uid()}>
            <td>Advanced</td>
            <td>{stripe.stats ? stripe.stats.total || 0 : 0}</td>
            <td>{stripe.stats ? stripe.stats.used || 0 : 0}</td>
            <td>{stripe.stats ? stripe.stats.available || 0 : 0}</td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
  </Card>
);

const UserWorkspacesView = ({ user, workspaces }) => (
  <Row>
    <Col md={4} xs={12}>
      <Card style={{ paddingTop: 75 }}>
        <CardBody>
          <CardTitle className="text-center">{user.fullname}</CardTitle>
          <p className="description text-center">{user.email}</p>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="button-container">
            <Row>
              <Col className="ml-auto">
                <p className="text-center">
                  All workspaces: {workspaces.data.length}
                </p>
              </Col>
            </Row>
          </div>
        </CardFooter>
      </Card>
    </Col>
    <Col md={8} xs={12}>
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader tag="h5">Available Workspaces</CardHeader>
            <CardBody>
              <ListGroup>
                {workspaces.data.map(workspace => (
                  <ListGroupItem>{workspace.name}</ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  </Row>
);

const UserView = ({
  user,
  stripe,
  removeSubs,
  toggleRemoveAdvanced,
  advanced,
  workspaces,
  used,
  addSubs,
  handleChange,
  loaded,
  show
}) => (
  <div className="content">
    <Loader loaded={loaded} />

    <UserWorkspacesView user={user} workspaces={workspaces} />
    {/* Uncomment these when stripe payment is available */}
    {/* <SubscriptionsView stripe={stripe} />

    <AssingUsersView
      workspaces={workspaces}
      addSubs={addSubs}
      stripe={stripe}
      handleChange={handleChange}
    />

    <AssignedUsersView
      advanced={advanced}
      toggleRemoveAdvanced={toggleRemoveAdvanced}
      show={show}
      used={used}
      user={user}
      removeSubs={removeSubs}
    /> */}
  </div>
);

export default UserView;
