import React from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import StripeCheckout from 'react-stripe-checkout';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

const options = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 }
];

const StripeCheckoutButton = ({
  user,
  amount,
  notifyRef,
  getValue,
  changeQuantity,
  quantity,
  price,
  onToken,
  onClosed,
  onOpened
}) => (
  <div>
    <Select
      value={getValue(quantity, options)}
      onChange={changeQuantity}
      options={options}
    />
    <br />
    <Row>
      <Col xs={6} sm={6} md={6}>
        <StripeCheckout
          amount={amount * quantity}
          image="/static/media/moodbit.1874827a.png"
          name="Moodbit Pro"
          description="Access to Moodbit Pro Analytics"
          locale="auto"
          stripeKey={STRIPE_KEY}
          token={onToken}
          closed={onClosed}
          opened={onOpened}
          email={user.email}
        />
      </Col>
      <Col xs={6} sm={6} md={6} className="mr-auto">
        <div style={{ fontSize: 16 }}>Total cost: ${price * quantity} </div>
      </Col>
    </Row>
    <br />
  </div>
);

export default StripeCheckoutButton;
