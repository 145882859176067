const initState = {
  id: -1,
  name: 'Select Channel',
  dashboardData: {
    happy: { arr: [] },
    sad: { arr: [] },
    neutral: { arr: [] }
  }
};

export default initState;
