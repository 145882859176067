import get from './requests/get';
import BaseLambda from '../common/lambda';

class Lambda extends BaseLambda {
  get(payload) {
    return this._get(payload);
  }
}

export default new Lambda(get);
