import { Auth } from 'aws-amplify';
import Logger from '../logger';
import Wait from '../wait';

import Cognito from '../../config/cognito';

const MAX_RETRY = 5;
const DEFAULT_VALUE = 1;

class OAuth {
  static redirect() {
    const config = { ...Cognito.config() };
    const { domain, redirectSignIn, responseType } = config.oauth;

    const clientId = config.userPoolWebClientId;
    // The url of the Cognito Hosted UI
    const url = `https://${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}`;

    // Launch hosted UI
    Logger.info('Redirecting to Cognito Login', url);
    window.location.assign(url);
  }

  static async credentials() {
    // return Auth.currentCredentials().then(credentials => credentials);
    return Auth.currentSession().then(credentials => ({
      sessionId: credentials.getIdToken().getJwtToken(),
      sessionKey: credentials.getAccessToken().getJwtToken(),
      sessionToken: credentials.getRefreshToken().getToken(),
      credentials
    }));
  }

  static async token(retry = DEFAULT_VALUE) {
    return Auth.currentSession()
      .then(credentials => {
        const token = credentials.getAccessToken().getJwtToken();
        if (token === '' || token === null) {
          return Promise.reject(new Error('Token not Found'));
        }

        OAuth.credentials().then(session => {
          Logger.info('Session', session);
        });

        Logger.info('Credentials Found', credentials);
        Logger.info('Token', token);

        return token;
      })
      .catch(error => {
        Logger.error('Error Getting Session', error);
        if (retry > MAX_RETRY) {
          Logger.info('Max Rety Count Reached, Showing Login Screen');
          OAuth.redirect();
          return Promise.reject(error);
        }
        Logger.info('Retrying Getting Session', retry);
        return Wait().then(() => this.token(retry + DEFAULT_VALUE));
      });
  }

  // Syntax Sugar
  static async get(retry = DEFAULT_VALUE) {
    return this.token(retry);
  }

  static async user() {
    return Auth.currentAuthenticatedUser();
  }

  static async userInfo() {
    return Auth.currentUserInfo();
  }

  static async signOut() {
    await Auth.signOut().then(() => {
      localStorage.clear();
      OAuth.redirect();
    });
  }
}

export default OAuth;
