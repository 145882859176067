import Auth from '../../../../../../helpers/oauth';
import Logger from '../../../../../../helpers/logger';

async function params(dashboardId, workspaceIds) {
  return Auth.credentials().then(credentials =>
    Auth.user().then(user => {
      const data = {
        email: user.attributes.email,
        sessionId: credentials.sessionId,
        dashboardId,
        workspaceIds
      };

      Logger.info('Sending Data', data);
      return data;
    })
  );
}

export default params;
