import Logger from '../../../../../helpers/logger';

async function request(client, params, transformer) {
  Logger.info('Starting Lambda Request');
  return params().then(data => {
    Logger.info('Params Obtained', data);
    return client.post(data).then(response => {
      Logger.info('Transforming Response');
      return transformer(response);
    });
  });
}

export default request;
