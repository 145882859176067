import OAuth from '../../../../helpers/oauth';
import DuckOAuth from '../../../../helpers/oauth/ducks/get';

import MainData from '../../../../common/domain/lambda/fn-dashboard-main-data';
import DuckPermissions from '../../../../common/domain/lambda/fn-dashboard-get-user-permissions/ducks/get';

// import DuckMainData from '../../../../common/domain/lambda/fn-dashboard-main-data/ducks/get';

// import TeamData from '../../../../common/domain/lambda/fn-dashboard-team-data';
// import DuckTeamData from '../../../../common/domain/lambda/fn-dashboard-team-data/ducks/get';

import StripeSubscriptionsData from '../../../../common/domain/lambda/fn-stripe-subscriptions';
import DuckStripeSubscriptionsData from '../../../../common/domain/lambda/fn-stripe-subscriptions/ducks/get';

import UserData from '../../../../common/domain/lambda/fn-get-user-details';
import DuckUserData from '../../../../common/domain/lambda/fn-get-user-details/ducks/get';

import actions from './actions';
import Logger from '../../../../helpers/logger';

let workspaces = null;

const get = () => dispatch => {
  Logger.info('Begin Preloading Data');
  Logger.info('Getting OAuth');
  localStorage.clear();
  OAuth.get()
    .then(token => {
      Logger.info('Got OAuth Token', token);
      DuckOAuth.triggers.success(dispatch, token);
      Logger.info('Getting Main Data');

      return MainData.get().then(response => {
        workspaces = response;
        Logger.info('Workspaces', workspaces);
        // DuckPermissions.triggers.get(dispatch);
        return workspaces;
      });
    })
    // This was commented because it takes super long time
    // may be a better solution should be created
    // .then(main => {
    //   Logger.info('Got Main Data', main);
    //   DuckMainData.triggers.success(dispatch, main);
    //   Logger.info('Getting Team Data');
    //   return TeamData.get();
    // })
    // .then(team => {
    //   Logger.info('Got Team Data', team);
    //   DuckTeamData.triggers.success(dispatch, team);
    //   Logger.info('Get Stripe Data');
    // })
    .then(() => StripeSubscriptionsData.get())
    .then(stripe => {
      Logger.info('Got Stripe Data', stripe);
      DuckStripeSubscriptionsData.triggers.success(dispatch, stripe);
      Logger.info('Get User Info');
      return UserData.get();
    })
    .then(user => {
      Logger.info('Got User Data', user);
      DuckUserData.triggers.success(dispatch, user);
      return Promise.resolve();
    })
    // Final Step
    .then(() => {
      Logger.info('All data was Preloaded');

      // send gtag info
      Logger.info('Sending Gtag event');
      window.gtag('event', 'Dashboard', { view: 'Test' });
      dispatch(actions.success(true));
    })
    .catch(error => {
      Logger.error('Preload Failed', error);

      // This should trigger only if the slack workspace
      // is missing
      // 'https://slack.com/oauth/authorize?client_id=12612366289.381095307190&scope=bot,chat:write:bot,users.profile:read,users:read,commands,channels:read,groups:read,channels:write,groups:write'
      let redirect = false;
      if (error.message === 'No Workspace Configured') {
        // window.location.replace('https://mymoodbit.com/noworkspace.html');
        redirect = true;
      }

      if (error.message === 'No Data Found') {
        // window.location.replace('https://mymoodbit.com/nodata.html');
        redirect = true;
      }

      if (error.message === 'Unknown Payload Error') {
        // window.location.replace('https://mymoodbit.com/unknown.html');
        redirect = true;
      }

      if (redirect) {
        window.location.replace('https://mymoodbit.com/welcome.html');
      }

      dispatch(actions.fail(error));
    });
};

const triggers = {
  get
};

export default triggers;
