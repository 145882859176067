import client from '../../client';
import params from './request-params';
import transformer from './response-transformer';
import Logger from '../../../../../../helpers/logger';

async function request({ plan, quantity, deleteAll, item }) {
  return params(plan, quantity, deleteAll, item).then(data => {
    Logger.info('Calling with Params', data);
    return client.post(data).then(response => transformer(response));
  });
}

export default request;
