import OAuth from '../../../../../../helpers/oauth';

async function params(form, team, notification, formAction) {
  const token = await OAuth.token();
  return Promise.resolve({
    access_token: token,
    form,
    team,
    notification,
    formAction
    // workspace // : 'T0CJ0AS8H', // channel.workspace.id,
    // channel // channel.id
  });
}

export default params;
