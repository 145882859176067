import post from '../../../common/requests/post';
import client from '../../client';
import params from './request-params';
import transformer from './response-transformer';

function request() {
  return post(client, params, transformer);
}

export default request;
