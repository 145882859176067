import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardFooter,
  Row,
  Col
} from 'reactstrap';

import StripePaymentButton from '../../../common/components/StripePaymentButton';

const SubscriptionsView = ({ price, plan }) => (
  <div className="content">
    <Row>
      <Col sm={4}>
        <Card>
          <CardHeader className="text-center">
            <CardTitle>
              <h4>Moodbit for Slack</h4>
            </CardTitle>
            <CardSubtitle>Free</CardSubtitle>
          </CardHeader>
          <hr />
          <CardBody>
            <Col className="ml-auto">
              <ul>
                <li>Access to Standard Analytics</li>
                {/* <li>Enhanced Insights into Employee moods</li>
                <li>Suggestions on how to improve your team's morale</li>
                <li>Track the sentiment of individual team members</li> */}
              </ul>
            </Col>
          </CardBody>
          <CardFooter>
            <Row className="justify-content-center">
              <a
                id="installSlack"
                href="https://slack.com/oauth/authorize?client_id=12612366289.381095307190&scope=bot,chat:write:bot,users.profile:read,users:read,commands,channels:read,groups:read,channels:write,groups:write"
                style={{ marginRight: '4px' }}
              >
                <img
                  alt="Add to Slack"
                  height="40"
                  width="139"
                  src="https://platform.slack-edge.com/img/add_to_slack.png"
                  srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                />
              </a>
            </Row>
          </CardFooter>
        </Card>
      </Col>
      {/* <Col sm={4}>
          <Card>
            <CardHeader className="text-center">
              <CardTitle>
                <h4>Moodbit Pro Standard Subscription</h4>
              </CardTitle>
              <CardSubtitle>${price.standard}/Email/Month</CardSubtitle>
            </CardHeader>
            <hr />
            <CardBody>
              <Col className="ml-auto">
                <ul>
                  <li>Access to Advanced Analytics</li>
                  <li>Enhanced Insights into Employee moods</li>
                  <li>Suggestions on how to improve your team's morale</li>
                  <li>Track the sentiment of individual team members</li>
                </ul>
              </Col>
            </CardBody>
            <CardFooter>
              <StripePaymentButton price={price.standard} plan={plan.standard} />
            </CardFooter>
          </Card>
        </Col> */}
      <Col sm={4}>
        <Card>
          <CardHeader className="text-center">
            <CardTitle>
              <h4>Moodbit Pro Advanced Subscription</h4>
            </CardTitle>
            <CardSubtitle>${price.advanced}/Email/Month</CardSubtitle>
          </CardHeader>
          <hr />
          <CardBody>
            <Col className="ml-auto">
              <ul>
                <li>Access to Advanced Analytics</li>
                <li>Authorize Additional Users</li>
                <li>Notifications on how to improve your team's morale</li>
                {/* <li>Enhanced Insights into Employee moods</li> */}
                {/* <li>Suggestions on how to improve your team's morale</li> */}
                {/* <li>Track the sentiment of individual team members</li> */}
              </ul>
            </Col>
          </CardBody>
          <CardFooter>
            <StripePaymentButton price={price.advanced} plan={plan.advanced} />
          </CardFooter>
        </Card>
      </Col>
    </Row>
  </div>
);

export default SubscriptionsView;
