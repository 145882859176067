import React from 'react';

// notification options
const error = {
  place: 'tl',
  message: (
    <div>
      <div>
        Error occurred while processing payment. Please wait a few minutes and
        try again.
      </div>
    </div>
  ),
  type: 'primary',
  icon: 'nc-icon nc-alert-circle-i',
  autoDismiss: 7,
  closeButton: true
};

export default error;
