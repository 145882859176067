import React from 'react';

const options = {
  place: 'tl',
  message: (
    <div>
      <div>
        You have no subscriptions to remove. Please click the &quot;Add&quot;
        button to purchase more subscriptions!
      </div>
    </div>
  ),
  type: 'info',
  icon: 'nc-icon nc-alert-circle-i',
  autoDismiss: 5,
  closeButton: true
};

export default options;
