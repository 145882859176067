import React from 'react';

const options = {
  place: 'tl',
  message: (
    <div>
      <div>Something went wrong while removing subscriptions.</div>
    </div>
  ),
  type: 'danger',
  icon: 'nc-icon nc-alert-circle-i',
  autoDismiss: 4,
  closeButton: true
};

export default options;
