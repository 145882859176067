import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Loader from 'react-loader';

import GetActionPlans from '../../common/domain/lambda/fb-moodbit-actionplans';
import getNotifications from '../../common/domain/lambda/fn-moodbit-notifications';
import updateNotifications from '../../common/domain/lambda/fn-moodbit-notifications-updater';

import DuckMainData from '../../common/domain/lambda/fn-dashboard-main-data/ducks/get';
import DuckPermissions from '../../common/domain/lambda/fn-dashboard-get-user-permissions/ducks/get';
import RestrictedArea from '../restrictedarea';
import Logger from '../../helpers/logger';

import Uid from '../../helpers/uid';

const SelectedActionView = ({
  action = {
    uid: '',
    topic: '',
    actions: '',
    about: '',
    link: { url: '', about: '' },
    send_at: { raw: new Date() },
    gif: ''
  },
  operations
}) =>
  action.uid ? (
    <div>
      <h5>Action Plan to Send</h5>
      <table className="table">
        <tr>
          <th>Topic</th>
          <th>Action</th>
          <th>About</th>
          <th>Link</th>
          <th>Gif</th>
          <th>Remove</th>
        </tr>
        <tbody>
          <tr key={action.uid}>
            <td>
              <p>{action.topic}</p>
            </td>
            <td>
              <p>{action.actions}</p>
            </td>
            <td>
              <p>{action.about}</p>
            </td>
            <td>
              {action.link ? (
                <a href={action.link.url || ''}>{action.link.about || ''}</a>
              ) : null}
            </td>
            <td>
              <img src={action.gif} alt="GIF" />
            </td>
            <td>
              <button
                type="button"
                className="btn btn-danger"
                onClick={event => {
                  window.notifyRef.current.notificationAlert({
                    place: 'tl',
                    message: 'Prescription Removed',
                    type: 'success',
                    icon: 'nc-icon nc-alert-circle-i',
                    autoDismiss: 7,
                    closeButton: true
                  });
                  operations.clearSelectedAction();
                }}
              >
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;

const FormView = ({ data, operations, form, formAction }) => (
  <div>
    <h5>Workspaces</h5>
    <form>
      <table className="table">
        <tr>
          <th>Workspace</th>
          <th>Notification Type</th>
          <th>Day</th>
          <th>Hour</th>
          <th>Prescription</th>
          <th>Enabled?</th>
          <th>Actions</th>
        </tr>
        <tbody>
          {data.schedules.map(schedule => {
            if (schedule.notification.type.toLowerCase() === 'actionplan') {
              return (
                <tr key={schedule.uid}>
                  <td>
                    <p>{schedule.team.name}</p>
                  </td>
                  <td>
                    <p>{schedule.notification.name}</p>
                  </td>
                  <td>
                    <select
                      name="day"
                      className="input"
                      onChange={event =>
                        operations.change({
                          name: 'day',
                          uid: schedule.uid,
                          value: parseInt(event.target.value, 10),
                          event
                        })
                      }
                    >
                      {data.days.values.map(day => (
                        <option
                          key={Uid()}
                          value={`${day.index}`}
                          selected={
                            form[schedule.uid] &&
                            parseInt(form[schedule.uid].day, 10) >= 0
                              ? day.index === form[schedule.uid].day
                              : day.index === schedule.schedule.day
                          }
                        >
                          {day.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      name="hour"
                      className="input"
                      onChange={event =>
                        operations.change({
                          name: 'hour',
                          uid: schedule.uid,
                          value: parseInt(event.target.value, 10),
                          event
                        })
                      }
                    >
                      {data.hours.map(hour => (
                        <option
                          key={Uid()}
                          value={`${hour.time24}`}
                          selected={
                            form[schedule.uid] &&
                            parseInt(form[schedule.uid].hour, 10) >= 0
                              ? hour.time24 === form[schedule.uid].hour
                              : hour.time24 === schedule.schedule.hour
                          }
                        >
                          {hour.time} {hour.ampm}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    {schedule.action.uid != null &&
                    schedule.action.uid !== '' ? (
                      <strong>{schedule.action.action}</strong>
                    ) : (
                      <strong>Automatic</strong>
                    )}
                    <br />
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={operations.toggleShowActions}
                    >
                      Choose Prescription
                    </button>
                    {/* <select
                      name="timezone"
                      className="input"
                      onChange={event =>
                        operations.change({
                          name: 'timezone',
                          uid: schedule.uid,
                          value: event.target.value,
                          event
                        })
                      }
                    >
                      {data.timezones.map(tz => (
                        <option
                          key={Uid()}
                          value={tz}
                          selected={
                            form[schedule.uid] && form[schedule.uid].timezone
                              ? tz === form[schedule.uid].timezone
                              : tz === schedule.schedule.timezone
                          }
                        >
                          {tz}
                        </option>
                      ))}
                    </select>
                    */}
                  </td>
                  <td>
                    <select
                      name="state"
                      className="input"
                      onChange={event =>
                        operations.change({
                          name: 'state',
                          uid: schedule.uid,
                          schedule,
                          value: parseInt(event.target.value, 10),
                          event
                        })
                      }
                    >
                      {data.states.map(state => (
                        <option
                          key={Uid()}
                          value={`${state.index}`}
                          selected={
                            form[schedule.uid] &&
                            parseInt(form[schedule.uid].state, 10) >= 0
                              ? state.index === form[schedule.uid].state
                              : state.index === schedule.state.value
                          }
                        >
                          {state.value}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={event => {
                        // force a form update before sending
                        operations.change({
                          name: 'send',
                          event,
                          schedule,
                          uid: schedule.uid,
                          value: true
                        });

                        operations.submit({
                          uid: schedule.uid,
                          event,
                          team: schedule.team,
                          form: form[schedule.uid],
                          schedule
                        });
                      }}
                    >
                      Save Changes
                    </button>
                  </td>
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </table>
    </form>
  </div>
);

const View = ({ actions, operations, topic, showActions }) => {
  const topics = {};
  actions.forEach(action => {
    topics[action.topic] = action.topic;
  });

  if (showActions) {
    return (
      <div>
        <h5>Prescriptive Action List</h5>
        <table className="table">
          <tr>
            <th>
              Topic <br />{' '}
              <select
                name="topic"
                className="input"
                onChange={event => operations.changedTopic(event.target.value)}
              >
                <option value="all">All</option>
                {Object.values(topics).map(topicv => (
                  <option value={topicv}>{topicv}</option>
                ))}
              </select>
            </th>
            <th>Action</th>
            <th>About</th>
            <th>Link</th>
            <th>Send At</th>
            <th>Gif</th>
            <th>Select</th>
          </tr>
          <tbody>
            {actions.map(action => {
              if (action.topic === topic || topic === 'all') {
                return (
                  <tr key={action.uid}>
                    <td>
                      <p>{action.topic}</p>
                    </td>
                    <td>
                      <p>{action.actions}</p>
                    </td>
                    <td>
                      <p>{action.about}</p>
                    </td>
                    <td>
                      <a href={action.link.url}>{action.link.about}</a>
                    </td>
                    <td>{moment(action.send_at.raw).format('YYYY-MM-DD')}</td>
                    <td>
                      <img src={action.gif} alt="GIF" />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn"
                        onClick={event => {
                          window.notifyRef.current.notificationAlert({
                            place: 'tl',
                            message:
                              'Prescription Selected. Press "Save Changes"  button to overwrite.',
                            type: 'success',
                            icon: 'nc-icon nc-alert-circle-i',
                            autoDismiss: 7,
                            closeButton: true
                          });

                          operations.submitAction({
                            name: 'action',
                            uid: action.uid,
                            value: action,
                            event
                          });
                          operations.toggleShowActions();
                        }}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                );
              }

              return null;
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

class Prescriptive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        schedules: []
      },
      form: {},
      formaction: {},
      authorized: false,
      loaded: false,
      actions: [],
      topic: 'all',
      showActions: false
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onSubmitFormAction = this.onSubmitFormAction.bind(this);
    this.clearSelectedAction = this.clearSelectedAction.bind(this);
    this.changedTopic = this.changedTopic.bind(this);
    this.toggleShowActions = this.toggleShowActions.bind(this);
  }

  componentDidMount() {
    const { getPermissions } = this.props;

    getPermissions();
    this.checkPermissions();
    this.getNotifications();

    GetActionPlans.get().then(actions => {
      this.setState({ actions });
    });

    window.notifyRef.current.notificationAlert({
      place: 'tl',
      message:
        'You can choose your Workspace Presctiption by clicking "Choose Prescription".',
      type: 'success',
      icon: 'nc-icon nc-alert-circle-i',
      autoDismiss: 7,
      closeButton: true
    });
  }

  onChangeHandler({ name, uid, value, event, schedule }) {
    Logger.debug('Changed Event', { name, uid, value, event, schedule });
    const { form } = this.state;
    let data = form[uid];
    if (!data) {
      form[uid] = { uid };
      data = form[uid];
    }
    data[name] = value;
    Logger.debug('Schedule Form', data);
    this.setState({ form });
  }

  onSubmitFormAction({ name, uid, value, event }) {
    Logger.info('Form Action', value);
    this.setState({ formaction: value });
  }

  onSubmitHandler({ name, uid, event, form, team, schedule }) {
    const { formaction } = this.state;
    if (form) {
      updateNotifications
        .get({ form, team, schedule, formAction: formaction })
        .then(response => {
          Logger.debug('Form Submitted', {
            name,
            uid,
            event,
            form,
            formaction
          });
          Logger.debug(response);

          // alert('Settings Saved');
          const success = {
            place: 'tl',
            message: 'Settings Saved',
            type: 'success',
            icon: 'nc-icon nc-alert-circle-i',
            autoDismiss: 7,
            closeButton: true
          };
          window.notifyRef.current.notificationAlert(success);

          this.setState({ formaction: {} });
          this.setState({ form: {} });
          // window.location.reload(false);
          this.getNotifications();
        });
    } else {
      // alert('Settings Saved');
      Logger.info('Could not save');
    }
  }

  getNotifications = () => {
    const { workspaces } = this.props;
    Logger.info('Available Workspaces', workspaces);
    const workspaceIds = workspaces.data.map(item => item.id);

    getNotifications
      .get({ workspaces: workspaceIds })
      .then(data => {
        Logger.debug('Notifications', data);
        this.setState({ data });
      })
      .catch(() => {});
  };

  checkPermissions = () => {
    const { permissions } = this.props;
    Logger.info('Permissions', permissions);
    if (
      permissions &&
      permissions.loaded &&
      permissions.data &&
      permissions.data.notifications
    ) {
      Logger.info('User is authorized to this view');
      this.setState({ authorized: true });
    }
  };

  toggleShowActions = () => {
    const { showActions } = this.state;
    this.setState({ showActions: !showActions });
  };

  changedTopic = topic => {
    this.setState({ topic });
  };

  clearSelectedAction() {
    Logger.info('Clearing Action');
    this.setState({ formaction: {} });
  }

  render() {
    const {
      data,
      form,
      formaction,
      loaded,
      actions,
      authorized,
      topic,
      showActions
    } = this.state;
    const { permissions } = this.props;
    const operations = {
      submit: this.onSubmitHandler,
      change: this.onChangeHandler,
      changeAction: this.onChangeFormAction,
      submitAction: this.onSubmitFormAction,
      clearSelectedAction: this.clearSelectedAction,
      changedTopic: this.changedTopic,
      toggleShowActions: this.toggleShowActions
    };

    if (permissions.loaded && !authorized) {
      Logger.info('Not authorized!', permissions);
      return RestrictedArea();
    }

    const formView = FormView({
      data,
      operations,
      form,
      formAction: formaction
    });

    const actionList = View({
      actions,
      operations,
      topic,
      showActions
    });

    const selectedAction = SelectedActionView({
      action: formaction,
      operations
    });

    Logger.debug('Current Form', { form, formaction });

    return (
      <div>
        {formView}
        <br />
        {selectedAction}
        <br />
        {actionList}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const workspaces = state[DuckMainData.namespace];
  const permissions = state[DuckPermissions.namespace];
  return { workspaces, permissions };
};

const mapDispatchToProps = {
  getWorkspaces: DuckMainData.triggers.get,
  getPermissions: DuckPermissions.triggers.get
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Prescriptive);
