import types from './types';
import Logger from '../../../../../../helpers/logger';
import initState from './state';

const success = (state, action) => {
  if (action.type !== types.success) {
    return state;
  }

  Logger.info('Stripe Data Loaded');
  return {
    loaded: true,
    ...action.payload
  };
};

const fail = (state, action) => {
  if (action.type !== types.fail) {
    return state;
  }
  Logger.warn('Permissions Data Failed to Load');
  return { error: action.payload, ...initState };
};

const loading = (state, action) => {
  if (action.type !== types.loading) {
    return state;
  }
  Logger.warn('Loading Permissions Data');
  return initState;
};

const reducers = {
  success,
  fail,
  loading
};

export default reducers;
