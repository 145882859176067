import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader';
import Footer from '../../../common/components/Footer';
import Header from '../../../common/components/Header';
import Logger from '../../../helpers/logger';
import Uid from '../../../helpers/uid';

import MoodbitSidebar from '../../../common/components/MoodbitSidebar';
import routes from '../../../common/components/MoodbitSidebar/routes';

import DuckPreloader from '../ducks/preload';
import DuckPermissions from '../../../common/domain/lambda/fn-dashboard-get-user-permissions/ducks/get';

// TODO: Separate between Views and Controllers

let ps;

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.mainPanel = React.createRef();
  }

  componentDidMount() {
    Logger.info('Getting Stripe data in Layouts');
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }

    const { preload, getPermissions } = this.props;
    const { ready } = this.props;

    if (!ready) {
      preload();
      getPermissions();
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }

    const { ready } = this.props;
    Logger.info('Is Ready?', JSON.stringify(ready));
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.body.classList.toggle('perfect-scrollbar-on');
    }
  }

  render() {
    const { ready } = this.props;
    return (
      <Loader loaded={ready}>
        <div className="wrapper">
          <MoodbitSidebar {...this.props} />
          <div className="main-panel" ref={this.mainPanel}>
            <Header {...this.props} />
            <div className="content">
              <Switch>
                {routes.map(prop => {
                  if (prop.redirect) {
                    return (
                      <Redirect from={prop.path} to={prop.pathTo} key={Uid()} />
                    );
                  }
                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={Uid()}
                    />
                  );
                })}
              </Switch>
            </div>
            <Footer fluid />
          </div>
        </div>
      </Loader>
    );
  }
}

const mapDispatchToProps = {
  preload: DuckPreloader.triggers.get,
  getPermissions: DuckPermissions.triggers.get
};

const mapStateToProps = state => {
  const preloader = state[DuckPreloader.namespace];
  return { ready: preloader.ready };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
