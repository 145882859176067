import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';

import QuickSightAPI from '../../../common/domain/lambda/fn-quicksight-url';
import DuckMainData from '../../../common/domain/lambda/fn-dashboard-main-data/ducks/get';
import DuckPermissions from '../../../common/domain/lambda/fn-dashboard-get-user-permissions/ducks/get';
import RestrictedArea from '../../restrictedarea';

import Logger from '../../../helpers/logger';

class Predictive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      loaded: false,
      authorized: false
    };
  }

  componentDidMount() {
    // this.getUrl();
    const { getWorkspaces, getPermissions } = this.props;
    getPermissions();
    this.checkPermissions();
    getWorkspaces();
    this.getUrl();
  }

  checkPermissions = () => {
    const { permissions } = this.props;
    Logger.info('Permissions', permissions);
    if (
      permissions &&
      permissions.loaded &&
      permissions.data &&
      permissions.data.predictive
    ) {
      Logger.info('User is authorized to this view');
      this.setState({ authorized: true });
    }

    // this.setState({ authorized: true });
  };

  getUrl = () => {
    const { workspaces } = this.props;
    Logger.info('Available Workspaces', workspaces);
    const workspaceIds = workspaces.data.map(item => item.id);

    Logger.info('Sending', workspaceIds);

    this.setState({ loaded: false });

    QuickSightAPI.get({
      dashboardId: 'a72ca7df-5190-4510-8dbe-122df3102dc4',
      workspaceIds
    }).then(url => {
      Logger.info('Got Url', url);
      this.setState({ url });
    });
  };

  hideLoadingSpinner = () => {
    Logger.info('Dashboard Iframe Loaded');
    this.setState({ loaded: true });
  };

  render() {
    const { url, loaded, authorized } = this.state;
    const { permissions } = this.props;

    if (!loaded) {
      setTimeout(() => {
        this.hideLoadingSpinner();
      }, 5000);
    }

    if (permissions.loaded && !authorized) {
      Logger.info('Not authorized!', permissions);
      return RestrictedArea();
    }

    return (
      <div>
        <Loader loaded={loaded} />
        <iframe
          title="quicksight"
          style={{ width: '100%', height: '700px', border: 'none' }}
          src={url}
          // for some reason this is not triggering, disabled for now
          // onLoad={this.hideLoadingSpinner}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const workspaces = state[DuckMainData.namespace];
  const permissions = state[DuckPermissions.namespace];
  return { workspaces, permissions };
};

const mapDispatchToProps = {
  getWorkspaces: DuckMainData.triggers.get,
  getPermissions: DuckPermissions.triggers.get
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Predictive);
