import types from './types';
import reducers from './reducers';
import triggers from './triggers';
import selectors from './selectors';
import initState from './state';
import namespace from './namespace';

const handlers = {
  [types.selected]: reducers.selected
};

const reducer = (state = initState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

const duck = {
  namespace,
  reducer,
  triggers,
  selectors,
  default: initState
};

export default duck;
