import namespace from '../../../../../../redux/namespace';
import ns from './namespace';

const success = `${namespace}${ns}/ducks/get/success`;
const fail = `${namespace}${ns}/ducks/get/fail`;

const types = {
  success,
  fail
};

export default types;
