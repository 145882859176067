import React from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
// import View from '../views';
import Loader from 'react-loader';
import Index from '../views/index';
// import Premium from '../views/premium';
import Logger from '../../../helpers/logger';
import DuckGetStripeData from '../../../common/domain/lambda/fn-stripe-subscriptions/ducks/get';

class Subscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalSubCount: 0,
      loading: true
    };
  }

  componentDidMount() {
    Logger.info('Subscriptions View Loaded');
    const { getStripeData } = this.props;
    getStripeData();
  }

  premiumUser() {
    const { totalSubCount } = this.state;
    if (totalSubCount > 0) {
      return true;
    }
    return false;
  }

  render() {
    const { loading } = this.state;

    return Index(this.props);
  }
}

Subscriptions.defaultProps = {
  price: {
    standard: 12,
    advanced: 24
  },
  plan: {
    standard: 'standard',
    advanced: 'advanced'
  }
};

Subscriptions.propTypes = {
  price: T.shape({
    standard: T.number.isRequired,
    advanced: T.number.isRequired
  }),
  plan: T.shape({
    standard: T.string.isRequired,
    advanced: T.string.isRequired
  })
};

const mapDispatchToProps = {
  getStripeData: DuckGetStripeData.triggers.get
};

const mapStateToProps = state => {
  const stripe = state[DuckGetStripeData.namespace];
  return stripe;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscriptions);
