import Logger from '../../../../../../helpers/logger';

const getUsersForChannel = ({ data, channel }) => {
  Logger.info('getUsersForChannel: Data Received', data);
  Logger.info('getUsersForChannel: Channel Received', channel);

  const empty = [];

  if (data && channel) {
    const workspace = data[channel.workspace.id];
    if (workspace) {
      const team = workspace.channels[channel.id];
      if (team && team.people) {
        Logger.info('getUsersForChannel: Returning', team.people);
        return team.people;
      }
      Logger.info('No Team People Found', team);
      return empty;
    }
    Logger.info('Not Workspace Found', workspace);
    return empty;
  }
  Logger.info(
    'getUsersForChannel: Data or Channel empty, returning empty array'
  );
  return empty;
};

const selectors = {
  getUsersForChannel
};

export default selectors;
