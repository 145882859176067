import OAuth from '../../../../../helpers/oauth';
import Logger from '../../../../../helpers/logger';

async function params() {
  const token = await OAuth.token();
  Logger.info('Token Obtained');
  if (!token) {
    Logger.error('Token Not Found');
    return Promise.reject(new Error('Token Not Found'));
  }
  return Promise.resolve(JSON.stringify({ access_token: token }));
}

export default params;
