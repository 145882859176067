async function transformer(response) {
  const data = {
    isDefault: false,
    name: response.data.user_name || '',
    fullname: response.data.user_realname || '',
    email: response.data.user_email || ''
  };

  return Promise.resolve(data);
}

export default transformer;
