import React from 'react';
import Uid from '../../helpers/uid';

const View = ({ data, operations, form }) => (
  <div>
    <form>
      <table className="table">
        <tr>
          <th>Workspace</th>
          <th>Notification Type</th>
          <th>Day</th>
          <th>Hour</th>
          <th>Timezone</th>
          <th>Enabled?</th>
          <th>Actions</th>
        </tr>
        <tbody>
          {data.schedules.map(schedule => {
            if (schedule.notification.type.toLowerCase() === 'survey') {
              return (
                <tr key={schedule.uid}>
                  <td>
                    <p>{schedule.team.name}</p>
                  </td>
                  <td>
                    <p>{schedule.notification.name}</p>
                  </td>
                  <td>
                    <select
                      name="day"
                      className="input"
                      onChange={event =>
                        operations.change({
                          name: 'day',
                          uid: schedule.uid,
                          value: parseInt(event.target.value, 10),
                          event
                        })
                      }
                    >
                      {data.days.values.map(day => (
                        <option
                          key={Uid()}
                          value={`${day.index}`}
                          selected={
                            form[schedule.uid] &&
                            parseInt(form[schedule.uid].day, 10) >= 0
                              ? day.index === form[schedule.uid].day
                              : day.index === schedule.schedule.day
                          }
                        >
                          {day.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      name="hour"
                      className="input"
                      onChange={event =>
                        operations.change({
                          name: 'hour',
                          uid: schedule.uid,
                          value: parseInt(event.target.value, 10),
                          event
                        })
                      }
                    >
                      {data.hours.map(hour => (
                        <option
                          key={Uid()}
                          value={`${hour.time24}`}
                          selected={
                            form[schedule.uid] &&
                            parseInt(form[schedule.uid].hour, 10) >= 0
                              ? hour.time24 === form[schedule.uid].hour
                              : hour.time24 === schedule.schedule.hour
                          }
                        >
                          {hour.time} {hour.ampm}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      name="timezone"
                      className="input"
                      onChange={event =>
                        operations.change({
                          name: 'timezone',
                          uid: schedule.uid,
                          value: event.target.value,
                          event
                        })
                      }
                    >
                      {data.timezones.map(tz => (
                        <option
                          key={Uid()}
                          value={tz}
                          selected={
                            form[schedule.uid] && form[schedule.uid].timezone
                              ? tz === form[schedule.uid].timezone
                              : tz === schedule.schedule.timezone
                          }
                        >
                          {tz}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      name="state"
                      className="input"
                      onChange={event =>
                        operations.change({
                          name: 'state',
                          uid: schedule.uid,
                          schedule,
                          value: parseInt(event.target.value, 10),
                          event
                        })
                      }
                    >
                      {data.states.map(state => (
                        <option
                          key={Uid()}
                          value={`${state.index}`}
                          selected={
                            form[schedule.uid] &&
                            parseInt(form[schedule.uid].state, 10) >= 0
                              ? state.index === form[schedule.uid].state
                              : state.index === schedule.state.value
                          }
                        >
                          {state.value}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={event => {
                        operations.change({
                          name: 'submit',
                          uid: schedule.uid,
                          schedule,
                          value: true,
                          event
                        });

                        operations.submit({
                          uid: schedule.uid,
                          event,
                          team: schedule.team,
                          form: form[schedule.uid],
                          schedule
                        });
                      }}
                    >
                      Save Changes
                    </button>
                  </td>
                </tr>
              );
            }
            return null;
          })}
          {/* {data.schedules.map(schedule => (
            <tr key={schedule.uid}>
              <td>
                <p>{schedule.team.name}</p>
              </td>
              <td>
                <p>{schedule.notification.name}</p>
              </td>
              <td>
                <select
                  name="day"
                  className="input"
                  onChange={event =>
                    operations.change({
                      name: 'day',
                      uid: schedule.uid,
                      value: parseInt(event.target.value, 10),
                      event
                    })
                  }
                >
                  {data.days.values.map(day => (
                    <option
                      key={Uid()}
                      value={`${day.index}`}
                      selected={
                        form[schedule.uid] &&
                        parseInt(form[schedule.uid].day, 10) >= 0
                          ? day.index === form[schedule.uid].day
                          : day.index === schedule.schedule.day
                      }
                    >
                      {day.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  name="hour"
                  className="input"
                  onChange={event =>
                    operations.change({
                      name: 'hour',
                      uid: schedule.uid,
                      value: parseInt(event.target.value, 10),
                      event
                    })
                  }
                >
                  {data.hours.map(hour => (
                    <option
                      key={Uid()}
                      value={`${hour.time24}`}
                      selected={
                        form[schedule.uid] &&
                        parseInt(form[schedule.uid].hour, 10) >= 0
                          ? hour.time24 === form[schedule.uid].hour
                          : hour.time24 === schedule.schedule.hour
                      }
                    >
                      {hour.time} {hour.ampm}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  name="timezone"
                  className="input"
                  onChange={event =>
                    operations.change({
                      name: 'timezone',
                      uid: schedule.uid,
                      value: event.target.value,
                      event
                    })
                  }
                >
                  {data.timezones.map(tz => (
                    <option
                      key={Uid()}
                      value={tz}
                      selected={
                        form[schedule.uid] && form[schedule.uid].timezone
                          ? tz === form[schedule.uid].timezone
                          : tz === schedule.schedule.timezone
                      }
                    >
                      {tz}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  name="state"
                  className="input"
                  onChange={event =>
                    operations.change({
                      name: 'state',
                      uid: schedule.uid,
                      schedule,
                      value: parseInt(event.target.value, 10),
                      event
                    })
                  }
                >
                  {data.states.map(state => (
                    <option
                      key={Uid()}
                      value={`${state.index}`}
                      selected={
                        form[schedule.uid] &&
                        parseInt(form[schedule.uid].state, 10) >= 0
                          ? state.index === form[schedule.uid].state
                          : state.index === schedule.state.value
                      }
                    >
                      {state.value}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={event =>
                    operations.submit({
                      uid: schedule.uid,
                      event,
                      team: schedule.team,
                      form: form[schedule.uid],
                      schedule
                    })
                  }
                >
                  Save Changes
                </button>
              </td>
            </tr>
          ))} */}
        </tbody>
      </table>
    </form>
  </div>
);

export default View;
