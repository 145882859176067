import User from '../../../../screens/user/controllers';
import Subscriptions from '../../../../screens/subscriptions/controllers';
import Predictive from '../../../../screens/predictive/controllers/quicksight';
import Descriptive from '../../../../screens/descriptive';
import Prescriptive from '../../../../screens/prescriptive';
import Survey from '../../../../screens/survey';
import Logout from '../../../../screens/logout';
import Wordcloud from '../../../../screens/wordcloud';

// import ComingSoon from '../../../../screens/comingsoon';
// import Dashboard from '../../../../screens/dashboard/controllers';
// import PredictiveAnalytics from '../../../../screens/analytics/controllers';
// import Company from '../../../../screens/company/controllers';
// import Auth from '../../../../helpers/oauth';
// import OAuth from '../../../../helpers/oauth';
// import Prescriptive from '../../../../screens/prescription';

const routes = [
  // Occult
  // {
  //   notInSidebar: true,
  //   path: '/actionplan',
  //   name: 'Testing Action Plan',
  //   icon: 'nc-icon nc-planet',
  //   component: ActionPlan
  // },
  {
    notInSidebar: true,
    path: '/logout',
    name: 'Logout',
    component: Logout
  },

  // {
  //   notInSidebar: true,
  //   path: '/actionplans',
  //   name: 'Testing Action Plans',
  //   icon: 'nc-icon nc-planet',
  //   component: ActionPlans
  // },
  // {
  //   notInSidebar: true,
  //   path: '/test-analytics',
  //   name: 'Testing Analytics',
  //   icon: 'nc-icon nc-planet',
  //   component: Analytics
  // },
  // Not occult
  {
    path: '/descriptive',
    name: 'Descriptive Analytics',
    icon: 'nc-icon nc-chart-bar-32',
    component: Descriptive
  },
  {
    path: '/predictive',
    name: 'Predictive Analytics',
    icon: 'nc-icon nc-vector',
    component: Predictive
  },
  {
    path: '/prescriptive',
    name: 'Prescriptive Actions',
    icon: 'nc-icon nc-watch-time',
    component: Prescriptive
  },

  {
    path: '/wordcloud',
    name: 'Wordcloud',
    icon: 'nc-icon nc-cloud-upload-94',
    component: Wordcloud,
    notInSidebar: false
  },

  {
    path: '/survey',
    name: 'Pulse Survey',
    icon: 'nc-icon nc-bell-55',
    component: Survey,
    notInSidebar: false
  },

  // {
  //   notInSidebar: true,
  //   pro: true,
  //   path: '/upgrade',
  //   name: 'Upgrade',
  //   icon: 'nc-icon nc-air-baloon',
  //   component: Dashboard
  // },
  // {
  //   notInSidebar: true,
  //   sup: true,
  //   path: '/support',
  //   name: 'Support',
  //   icon: 'nc-icon nc-send',
  //   component: Dashboard
  // },
  // {
  //   notInSidebar: true,
  //   path: '/account',
  //   name: 'Account',
  //   component: Dashboard
  // },
  // {
  //   notInSidebar: true,
  //   path: '/payments',
  //   name: 'Payments',
  //   component: Dashboard
  // },
  {
    notInSidebar: true, // Change to false to show subscriptions when stripe is ready
    path: '/subscriptions',
    name: 'Subscriptions',
    icon: 'nc-icon nc-credit-card',
    component: Subscriptions
  },
  // {
  //   notInSidebar: true,
  //   path: '/subscriptions',
  //   name: 'Upgrade Subscriptions',
  //   icon: 'nc-icon nc-credit-card',
  //   component: ComingSoon
  // },
  {
    path: '/user',
    name: 'Profile',
    icon: 'nc-icon nc-single-02',
    component: User
  },
  // {
  //   path: '/quicksight',
  //   name: 'Latam',
  //   icon: 'nc-icon nc-globe',
  //   component: QuickSight
  // },

  // {
  //   path: '/company',
  //   name: 'Company Profile',
  //   icon: 'nc-icon nc-globe',
  //   component: Company
  // },
  {
    redirect: true,
    path: '/',
    pathTo: '/descriptive',
    name: 'Descriptive Analytics'
  }
];

// OAuth.user().then(user => {
//   const username = user ? user.attributes.email || '' : '';
//   console.log(username);
//   if (
//     username === 'ccastro@nekki.cl' ||
//     username === 'ccastro' ||
//     username === 'ajaldin@nekki.cl' ||
//     username === 'ajaldin' ||
//     username === 'mshoji@nekki.cl' ||
//     username === 'mshoji'
//   ) {
//     console.log('added menu');
//     routes.push({
//       path: '/quicksight',
//       name: 'Latam',
//       icon: 'nc-icon nc-globe',
//       component: QuickSight
//     });
//   },

// });

export default routes;
