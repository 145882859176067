import params from './request-params';
import transformer from './response-transformer';
import client from '../../client';

async function request({ form, team, schedule, formAction }) {
  return params(form, team, schedule, formAction).then(data =>
    client.post(data).then(response => transformer({ response }))
  );
}

export default request;
