import namespace from '../../../../redux/namespace';
import ns from './namespace';

const success = `${namespace}${ns}/ducks/preload/success`;
const fail = `${namespace}${ns}/ducks/preload/fail`;

const types = {
  success,
  fail
};

export default types;
