import axios from 'axios';

import Logger from '../../../helpers/logger';

class HttpClient {
  constructor(endpoint = '') {
    this._endpoint = endpoint;
    this._http = axios;
  }

  http() {
    return this._http;
  }

  async request(method, _endpoint = '', data = null, config = {}) {
    let task = Promise.resolve();
    let validMethod = false;

    let endpoint = this._endpoint;
    if (_endpoint !== '') {
      endpoint = _endpoint;
    }

    const configuration = config;
    // const configuration = {
    //   ...config,
    //   headers: {
    //     // 'Access-Control-Allow-Origin': '*'
    //   }
    // };

    if (method === 'get') {
      validMethod = true;
      task = this._http
        .get(endpoint, configuration)
        .then(response => response.data);
    }

    if (method === 'post') {
      validMethod = true;
      task = this._http.post(endpoint, data, configuration);
    }

    if (method === 'patch') {
      validMethod = true;
      task = this._http.patch(endpoint, data, configuration);
    }

    if (method === 'put') {
      validMethod = true;
      task = this._http.put(endpoint, data, configuration);
    }

    if (!validMethod) {
      Logger.warn('Unknown Http Method used', method);
    }

    return task
      .then(response => {
        Logger.trace(`Http ${method} Response`, { ...response });

        const error = response.data.errorMessage;
        if (error) {
          return Promise.reject(new Error(error));
        }

        return Promise.resolve(response);
      })
      .catch(error => {
        Logger.error('Error in HTTP Request');
        Logger.error({
          base: this._endpoint,
          url: endpoint,
          data,
          config,
          method
        });
        Logger.error('Error in requesting', this._endpoint);
        Logger.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * @param AxiosRequestConfig config
   * @return AxiosResponse data
   */
  async get(endpoint = '', config = null) {
    return this.request('get', endpoint, null, config);
  }

  /**
   * @param string endpoint
   * @param AxiosRequestConfig config
   * @return Promise with AxiosResponse data
   */
  async post(data = null, endpoint = '', config = null) {
    return this.request('post', endpoint, data, config);
  }

  async postAsForm(data, endpoint = '') {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    };
    return this.request('post', endpoint, data, config);
  }

  /**
   * @param string endpoint
   * @param AxiosRequestConfig config
   * @return Promise with AxiosResponse data
   */
  async patch(data = null, endpoint = '', config = null) {
    return this.request('patch', endpoint, data, config);
  }

  /**
   * @param string endpoint
   * @param AxiosRequestConfig config
   * @return Promise with AxiosResponse data
   */
  async put(data = null, endpoint = '', config = null) {
    return this.request('put', endpoint, data, config);
  }
}

export default HttpClient;
