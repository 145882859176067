import token from '../../../common/params/access-token';

async function params({ teamId, childName, childEmail }) {
  return token().then(accessToken => {
    const form = {};
    const _token = JSON.parse(accessToken);

    form.access_token = _token.access_token;
    form.team_id = teamId;
    form.child_email = childEmail;
    form.child_name = childName;

    return Promise.resolve(form);
  });
}

export default params;
