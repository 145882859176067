import actions from './actions';
import Logger from '../../../../../helpers/logger';

const select = workspace => {
  Logger.info('Selecting Workspace', workspace);
  return actions.selected(workspace);
};

const triggers = {
  select
};

export default triggers;
