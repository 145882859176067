import React from 'react';

import { connect } from 'react-redux';

import Logger from '../../../helpers/logger';
import StripePayment from '../../domain/lambda/fn-stripe-payment';
import DuckStripeButton from './ducks/state';
import DuckUserInfo from '../../domain/lambda/fn-get-user-details/ducks/get';
import View from './views';
import * as Notifications from './notifications';

const MIN_QUANTITY = 1;
const STRIPE_MULTIPLIER = 100;

class StripePaymentButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: MIN_QUANTITY
    };
  }

  componentDidMount = () => {
    Logger.info('Payment Button Component Did Mount');
    Logger.info(this.props);
    Logger.info('Notify Ref', this.notifyRef);
  };

  onToken = token => {
    Logger.info('Payment Token Acquired', token);
    Logger.info('Calling Lambda Payment Function');

    const { quantity } = this.state;
    const { plan } = this.props;
    Logger.info('Quantity', quantity);

    window.notifyRef.current.notificationAlert({
      place: 'tl',
      message: 'Starting Payment Process',
      type: 'success',
      icon: 'nc-icon nc-alert-circle-i',
      autoDismiss: 7,
      closeButton: true
    });

    StripePayment.post({ token, quantity, plan })
      .then(response => {
        Logger.trace('Response Received', response);
        window.notifyRef.current.notificationAlert(Notifications.Success);
        // this.notifySuccess();
        // alert('Pucharse Complete');
      })
      .catch(error => {
        Logger.error(error);
        window.notifyRef.current.notificationAlert(Notifications.Error);
        // this.notifyError();
        // alert('Could not Complete Purchase');
      });
  };

  onOpened = () => {
    // Logger.info('Stripe checkout opened method called');
    const { open } = this.props;
    open();
  };

  onClosed = () => {
    // Logger.info('Stripe checkout closed method called');
    const { close } = this.props;
    close();
  };

  notifyError = () => {
    window.notifyRef.current.notificationAlert(Notifications.Error);
  };

  notifySuccess = () => {
    window.notifyRef.current.notificationAlert(Notifications.Success);
  };

  changeQuantity = item => {
    if (item.value >= MIN_QUANTITY) {
      return this.setState({ quantity: item.value });
    }
    return Logger.info('Quantity not Valid', item.value);
  };

  // returns an object in the form {value: integer, label: integer}
  getValue = (quantity, options) => {
    const currentOption = options.find(option => option.value === quantity);
    return currentOption;
  };

  render() {
    const { price } = this.props;
    const amount = price * STRIPE_MULTIPLIER;

    return View({
      ...this.props,
      ...this.state,
      amount,
      notifyRef: this.notifyRef,
      getValue: this.getValue,
      changeQuantity: this.changeQuantity,
      onToken: this.onToken,
      onClosed: this.onClosed,
      onOpened: this.onOpened
    });
  }
}

StripePaymentButton.defaultProps = {
  plan: 'standard',
  price: 12
};

const mapStateToProps = state => {
  const user = state[DuckUserInfo.namespace];
  return { user };
};

const mapDispatchToProps = {
  close: DuckStripeButton.triggers.close,
  open: DuckStripeButton.triggers.open
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripePaymentButton);
