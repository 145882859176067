import types from './types';

const selected = channel => ({
  type: types.selected,
  payload: channel
});

const actions = {
  selected
};

export default actions;
