const initState = {
  loaded: false,
  default: {
    id: -1,
    channels: {},
    name: 'Default'
  }
};

export default initState;
