import lambda from '../..';
import actions from './actions';

const get = channel => dispatch => {
  dispatch(actions.loading());
  lambda
    .get({ channel })
    .then(result => {
      dispatch(actions.success(result));
    })
    .catch(error => {
      dispatch(actions.fail(error));
    });
};

const success = (dispatch, data) => dispatch(actions.success(data));

const triggers = {
  get,
  success
};

export default triggers;
