const initState = {
  loaded: false,
  standard: {
    quantity: 0
  },
  advanced: {
    quantity: 0
  }
};

export default initState;
