import params from './request-params';
import transformer from './response-transformer';
import client from '../../client';
import Logger from '../../../../../../helpers/logger';

async function request(input) {
  const { token, quantity, plan } = { ...input };
  Logger.info('Stripe Token', token);
  Logger.info('Quantity', quantity);
  Logger.info('Plan', plan);
  return params(token, quantity, plan).then(data =>
    client.post(data).then(response => transformer(response))
  );
}

export default request;
