import Logger from '../../../../../../helpers/logger';

async function scrapeData(data) {
  const result = {};

  Object.keys(data.userMap).forEach(workspaceId => {
    const workspace = data.userMap[workspaceId];
    const channels = {};

    Object.keys(workspace.channels).forEach(channelId => {
      const channel = workspace.channels[channelId];
      channels[channelId] = {
        id: channelId,
        name: channel.channel_name || channelId,
        people: Object.keys(channel.users)
      };
    });

    result[workspaceId] = {
      id: workspaceId,
      name: workspace.team_name,
      channels
    };
  });

  Logger.info('Team User Map Data Scrapped', result);
  return result;
}

async function transformer(response) {
  let payload = null;
  try {
    payload = response.data;
  } catch (error) {
    Logger.error('Error', error);
    return Promise.reject(error);
  }

  if (!payload) {
    return Promise.reject(new Error('Dashboard Payload Not Found'));
  }
  const data = scrapeData(payload);
  return Promise.resolve(data);
}

export default transformer;
