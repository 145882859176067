import actions from './actions';
import Logger from '../../../../../helpers/logger';

const open = () => dispatch => {
  const action = actions.open();
  Logger.info('Dispatching Open', action);
  return dispatch(action);
};

const close = () => dispatch => {
  const action = actions.close();
  Logger.info('Dispatching Close', action);
  return dispatch(action);
};

const triggers = {
  open,
  close
};

export default triggers;
