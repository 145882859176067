import Logger from '../../../../../../helpers/logger';

const transformer = raw => {
  const { response } = raw;
  const { data } = response;
  Logger.info('Returning Data', data);
  return data.data;
};

export default transformer;
