import types from './types';
import Logger from '../../../../helpers/logger';

const success = (state, action) => {
  if (action.type !== types.success) {
    return state;
  }
  Logger.info('Preload Data Complete');
  return {
    ready: true
  };
};

const fail = (state, action) => {
  if (action.type !== types.fail) {
    return state;
  }
  Logger.warn('Preload Data Failed');
  return {
    ready: false
  };
};

const reducers = {
  success,
  fail
};

export default reducers;
