import types from './types';

const success = (state, action) => {
  if (action.type !== types.success) {
    return state;
  }
  return { loaded: true, token: action.payload };
};

const fail = (state, action) => {
  if (action.type !== types.fail) {
    return state;
  }
  return { loaded: false, token: '', error: action.payload };
};

const reducers = {
  success,
  fail
};

export default reducers;
