import React from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container
} from 'reactstrap';

import OAuth from '../../../helpers/oauth';

import routes from '../MoodbitSidebar/routes';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: 'transparent'
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor.bind(this));
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      this.refs.sidebarToggle.classList.toggle('toggled');
    }
  }

  getBrand() {
    let name;
    const props = { ...this.props };
    routes.map(property => {
      const route = { ...property }
      if (property.collapse) {
        property.views.map(prop => {
          if (prop.path === props.location.pathname) {
            name = { ...prop };
          }
          return null;
        });
      } else if (property.redirect) {
        if (property.path === props.location.pathname) {
          name = route.name;
        }
      } else if (property.path === props.location.pathname) {
        name = route.name;
      }
      return null;
    });
    return name;
  }

  toggle = () => {
    const state = { ...this.state };
    if (state.isOpen) {
      this.setState({
        color: 'transparent'
      });
    } else {
      this.setState({
        color: 'dark'
      });
    }
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  dropdownToggle = () => {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
  };

  logout = () => {
    OAuth.signOut();
  };

  openSidebar() {
    document.documentElement.classList.toggle('nav-open');
    this.refs.sidebarToggle.classList.toggle('toggled');
  }

  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    const state = { ...this.state };
    if (window.innerWidth < 993 && state.isOpen) {
      this.setState({
        color: 'dark'
      });
    } else {
      this.setState({
        color: 'transparent'
      });
    }
  }

  render() {
    const state = { ...this.state };
    const props = { ...this.props };
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          props.location.pathname.indexOf('full-screen-maps') !== -1
            ? 'dark'
            : state.color
        }
        expand="lg"
        className={
          props.location.pathname.indexOf('full-screen-maps') !== -1
            ? 'navbar-absolute fixed-top'
            : `navbar-absolute fixed-top ${
            state.color === 'transparent' ? 'navbar-transparent ' : ''
            }`
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref="sidebarToggle"
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand>{this.getBrand()}</NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={state.isOpen}
            navbar
            className="justify-content-end"
          >
            <a
              id="installSlack"
              href="https://slack.com/oauth/authorize?client_id=12612366289.381095307190&scope=bot,chat:write:bot,users.profile:read,users:read,commands,channels:read,groups:read,channels:write,groups:write"
              style={{ marginRight: '4px' }}
            >
              <img
                alt="Add to Slack"
                height="40"
                width="139"
                src="https://platform.slack-edge.com/img/add_to_slack.png"
                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
              />
            </a>
            <Nav navbar>
              <NavItem>
                <Link to="/user" className="nav-link btn-magnify">
                  <i className="nc-icon nc-single-02" />
                  <p>
                    <span className="d-lg-none d-md-block">Account</span>
                  </p>
                </Link>
              </NavItem>
              <Dropdown
                nav
                isOpen={state.dropdownOpen}
                toggle={e => this.dropdownToggle(e)}
              >
                <DropdownToggle caret={false} nav>
                  <i className="nc-icon nc-settings-gear-65" />
                  <p>
                    <span className="d-lg-none d-md-block">Settings</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header>Settings</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag="a" onClick={() => this.logout()}>
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
