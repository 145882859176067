import namespace from '../../../../../../redux/namespace';
import ns from './namespace';

const loading = `${namespace}${ns}/ducks/get/loading`;
const success = `${namespace}${ns}/ducks/get/success`;
const fail = `${namespace}${ns}/ducks/get/fail`;

const types = {
  success,
  fail,
  loading
};

export default types;
