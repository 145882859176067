import OAuth from '../..';
import actions from './actions';

const get = () => dispatch => {
  OAuth.token()
    .then(token => {
      dispatch(actions.success(token));
    })
    .catch(error => {
      dispatch(actions.fail(error));
    });
};

const success = (dispatch, token) => dispatch(actions.success(token));

const triggers = {
  get,
  success
};

export default triggers;
