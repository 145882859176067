import namespace from '../../../../../redux/namespace';
import ns from './namespace';

const selected = `${namespace}${ns}/ducks/select-channel/selected`;

const types = {
  selected
};

export default types;
