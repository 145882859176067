/**
 * This file is where the setup is done.
 */

// Absolute Imports
// Import React
import React from 'react';
import ReactDOM from 'react-dom';

// Import Libs
import Amplify from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-notification-alert/dist/animate.css';

import { Provider } from 'react-redux';
import store from './redux';

// Import Utils

// Relative Imports
// Import Configs
import Cognito from './config/cognito';

// Import Modules

// Import Styles

import './common/assets/scss/paper-dashboard.scss';
import './common/assets/demo/demo.css';
import './index.css';

// Import App
import App from './App';

Amplify.configure({
  Auth: Cognito.config()
});

// Render App
const AppWithAuthenticator = withOAuth(App);
ReactDOM.render(
  <Provider store={store}>
    <AppWithAuthenticator />
  </Provider>,
  document.getElementById('root')
);

export default AppWithAuthenticator;
