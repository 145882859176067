import token from '../../../common/params/access-token';

async function params(stripe, quantity = 1, plan = 'standard') {
  return token().then(accessToken => {
    const form = {};
    const _token = JSON.parse(accessToken);

    form.access_token = _token.access_token;
    form.quantity = quantity;
    form.stripe_token = stripe;
    form.plan = plan;

    return Promise.resolve(form);
  });
}
export default params;
