class Cognito {
  static config() {
    return {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'arn:aws:cognito-idp:us-east-1:025226033257:userpool/us-east-1_l51BmCJKY',
      // https://us-east-1.console.aws.amazon.com/cognito/code/?region=us-east-1&pool=us-east-1:be7e2e2b-da91-4227-87e7-fc9ae62bac90
      identityPoolId: 'us-east-1:be7e2e2b-da91-4227-87e7-fc9ae62bac90',

      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'us-east-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_l51BmCJKY',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '12u4mid9cj1l17mgffr4oln50n',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      oauth: {
        domain: 'my-moodbit.auth.us-east-1.amazoncognito.com',
        scope: [
          'phone',
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: process.env.REACT_APP_COGNITO_URL,
        redirectSignOut: process.env.REACT_APP_COGNITO_URL,
        responseType: 'code' // code or token
      }

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //     domain: '.mymoodbit.com',
      //     // OPTIONAL - Cookie path
      //     path: '/',
      //     // OPTIONAL - Cookie expiration in days
      //     expires: 365,
      //     // OPTIONAL - Cookie secure flag
      //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //     secure: true
      // },

      // OPTIONAL - customized storage object
      // storage: new MyStorage(),

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH'
    };
  }
}

export default Cognito;
