import Logger from '../../../../../../helpers/logger';

const scrapeData = payload => payload;

// function scrapeData(payload) {
//   const {
//     workspaceTeamMap,
//     teamhighCharts,
//     teamChannelMap,
//     channelIdNameMap
//   } = payload;

//   const workspaces = Object.keys(workspaceTeamMap).map(team => ({
//     id: team,
//     name: workspaceTeamMap[team]
//   }));

//   const data = workspaces.map(workspace => {
//     const channelIds = teamChannelMap[workspace.id];

//     const channels = [];
//     channelIds.forEach(id => {
//       const name = channelIdNameMap[id] || id;
//       const dashboardData = teamhighCharts[workspace.id].channelDisplayData[id];
//       channels.push({
//         id,
//         name,
//         dashboardData,
//         workspace
//       });
//     });

//     return {
//       ...workspace,
//       channels
//     };
//   });

//   return data;
// }

const errorCodes = {
  noWorkspaceFound: 1001,
  noDataFound: 1002,
  unknown: 1003
};

async function transformer(response) {
  Logger.info('Main Data Response', response);

  const payload = JSON.parse(response.data.body.Payload);
  if (!payload) {
    return Promise.reject(new Error('Payload Not Found'));
  }

  Logger.info('Main Data Payload', payload);

  if (payload.errorCode) {
    if (payload.errorCode === errorCodes.noWorkspaceFound) {
      Logger.info('No Payload Found, Need Slack Configuration');
      return Promise.reject(new Error('No Workspace Configured'));
    }

    if (payload.errorCode === errorCodes.noDataFound) {
      Logger.info('No Payload Found, Need More Data');
      return Promise.reject(new Error('No Data Found'));
    }
    Logger.info('Unknown Payload');
    return Promise.reject(new Error('Unknown Payload Error'));
  }

  Logger.info('Main Data OK');
  return Promise.resolve(scrapeData(payload));
}

export default transformer;
