import React from 'react';

const linkToProfile = (
  <a href="/user" style={{ color: 'blue' }}>
    profile
  </a>
);

const success = {
  place: 'tl',
  message: (
    <div>
      <div>Success! View your total slots on your {linkToProfile} page.</div>
    </div>
  ),
  type: 'success',
  icon: 'nc-icon nc-alert-circle-i',
  autoDismiss: 7,
  closeButton: true
};

export default success;
