/**
 * This file holds all the reducers used in the application
 * And combine them in one reducer used in the store init.
 */
import { combineReducers } from 'redux';
import DuckGetUserDetails from '../common/domain/lambda/fn-get-user-details/ducks/get';
import DuckChannelPickerSelectItem from '../common/components/ChannelPicker/ducks/select-channel';
import DuckWorkspacePickerSelectItem from '../common/components/WorkspacePicker/ducks/select-workspace';
import DuckGetTeamData from '../common/domain/lambda/fn-dashboard-team-data/ducks/get';
import DuckGetMainData from '../common/domain/lambda/fn-dashboard-main-data/ducks/get';
import DuckGetStripeSubscriptionsData from '../common/domain/lambda/fn-stripe-subscriptions/ducks/get';
import DuckGetSageMakerDeepArData from '../common/domain/lambda/fn-advanced-analytics-deepar/ducks/get';
import DuckOAuth from '../helpers/oauth/ducks/get';
import DuckPreload from '../screens/index/ducks/preload';
import DuckStripePaymentButton from '../common/components/StripePaymentButton/ducks/state';
import DuckPermissions from '../common/domain/lambda/fn-dashboard-get-user-permissions/ducks/get';

const ducks = [
  DuckGetUserDetails,
  DuckChannelPickerSelectItem,
  DuckGetMainData,
  DuckWorkspacePickerSelectItem,
  DuckGetTeamData,
  DuckGetStripeSubscriptionsData,
  DuckGetSageMakerDeepArData,
  DuckOAuth,
  DuckPreload,
  DuckStripePaymentButton,
  DuckPermissions
  // DuckModalPaymentButton
];

// Modals should not be saved to database
const notSavedDucks = [
  // DuckModalPaymentButton
  DuckStripePaymentButton
];

const savelist = {};
const reducers = {};

ducks.forEach(duck => {
  savelist[duck.namespace] = duck.namespace;
  reducers[duck.namespace] = duck.reducer;
});

notSavedDucks.forEach(duck => {
  if (savelist[duck.namespace]) {
    delete savelist[duck.namespace];
  }
});

export default {
  reducer: combineReducers(reducers),
  savelist: Object.keys(savelist)
};
