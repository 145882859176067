import React from 'react';

import { connect } from 'react-redux';
import T from 'prop-types';
import Loader from 'react-loader';
import Logger from '../../../helpers/logger';

import DuckGetUserDetails from '../../../common/domain/lambda/fn-get-user-details/ducks/get';
import DuckGetStripeData from '../../../common/domain/lambda/fn-stripe-subscriptions/ducks/get';
import DuckMainData from '../../../common/domain/lambda/fn-dashboard-main-data/ducks/get';
import DuckPermissions from '../../../common/domain/lambda/fn-dashboard-get-user-permissions/ducks/get';

import StripeAdd from '../../../common/domain/lambda/fn-stripe-create-subscription';
import StripeDelete from '../../../common/domain/lambda/fn-stripe-delete-subscriptions';
import View from '../views';
import * as Notifications from '../notifications';

const standard = 'standard';
const advanced = 'advanced';

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeStandardModal: false,
      removeAdvancedModal: false,
      deleteAllStandardModal: false,
      deleteAllAdvancedModal: false,
      removeQuantityStandard: 1,
      removeQuantityAdvanced: 1,
      loading: false,
      show: ''
    };

    this.form = {
      assignedUserEmail: '',
      assignedUserName: '',
      assignedUserWorkspace: ''
    };

    this.notifyRef = window.notifyRef;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { getUserDetails, getStripeData, getWorkspaces } = this.props;
    Logger.info('Getting User Data');
    getUserDetails();
    Logger.info('Getting Stripe Data');
    getStripeData();
    Logger.info('Getting Workspaces');
    getWorkspaces();
  }

  hideLoadingSpinner = () => {
    Logger.info('Loaded');
    this.setState({ loaded: true });
  };

  notifyError = options => {
    this.hideLoadingSpinner();
    this.notifyRef.current.notificationAlert(options);
  };

  notifySuccess = options => {
    this.hideLoadingSpinner();
    this.notifyRef.current.notificationAlert(options);
  };

  toggleRemoveAdvanced = item => {
    Logger.info('Toggle Modal for Item', item);
    // This is needed for the modal
    // so it will select the right item for team/email
    this.setState({ show: `${item.team.name}/${item.child.email}` });
  };

  addSubs = () => {
    const { assignedUserEmail, assignedUserName } = this.form;

    const { getStripeData, getPermissions } = this.props;

    this.setState({ loaded: false });

    Logger.info('Sending', this.form);

    // Note: this is using raw js to fetch the current selected option
    // TODO: implement this using react apis instead of direct js manipulation
    const e = document.getElementById('assignedUserWorkspace');
    const workspace = e.options[e.selectedIndex].value;

    StripeAdd.post({
      teamId: workspace,
      childName: assignedUserName,
      childEmail: assignedUserEmail
    })
      .then(response => {
        Logger.trace('Response received', response);
        getPermissions();
        this.notifySuccess({
          place: 'tl',
          message: (
            <div>
              <div>Added User.</div>
            </div>
          ),
          type: 'success',
          icon: 'nc-icon nc-check-2',
          autoDismiss: 4,
          closeButton: true
        });
        getStripeData();
      })
      .catch(error => {
        getPermissions();
        Logger.trace(
          'Error occurred when deleting subscriptions',
          JSON.stringify(error)
        );
        this.notifyError(Notifications.Error);
      });
  };

  removeSubs = (plan, item) => {
    const { removeQuantityStandard, removeQuantityAdvanced } = this.state;
    let quantity = removeQuantityStandard;
    if (plan === advanced) {
      quantity = removeQuantityAdvanced;
    }

    this.setState({ loaded: false });
    Logger.info('Removing Subs', { plan, quantity });

    Logger.info('Deleting All Subscriptions', JSON.stringify({ plan, item }));
    const { getStripeData, getPermissions } = this.props;

    StripeDelete.post({ plan, quantity, item })
      .then(response => {
        Logger.trace('Response received', response);
        getPermissions();
        if (plan === standard) {
          this.notifySuccess(Notifications.StandardSubsDeleted);
        } else {
          this.notifySuccess(Notifications.AdvancedSubsDeleted);
        }
        getStripeData();
      })
      .catch(error => {
        getPermissions();
        Logger.trace(
          'Error occurred when deleting subscriptions',
          JSON.stringify(error)
        );
        this.notifyError(Notifications.Error);
      });
  };

  setWorkspace = workspace => {
    const { selectWorkspace } = { ...this.props };
    selectWorkspace(workspace);
  };

  changeQuantityAdvanced = element => {
    const { target } = element;
    const { value } = target;

    if (value && value >= 1) {
      const { stripe } = this.props;
      if (value <= stripe.advanced.quantity) {
        Logger.info('Changed Advanced Quantity in', value);
        this.setState({ removeQuantityAdvanced: value });
      }
    }
  };

  handleChange({ target }) {
    this.form[target.name] = target.value;
  }

  render() {
    const { stripe } = this.props;
    const { used } = stripe;
    const { loaded, show } = this.state;

    if (!loaded) {
      setTimeout(() => {
        this.hideLoadingSpinner();
      }, 2000);

      return <Loader loaded={loaded} />;
    }

    return View({
      used,
      ...this.props,
      ...this.state,
      notifyReference: this.notifyRef,
      toggleRemoveAdvanced: this.toggleRemoveAdvanced,
      toggleRemoveStandard: this.toggleRemoveStandard,
      toggleDeleteAllAdvanced: this.toggleDeleteAllAdvanced,
      toggleDeleteAllStandard: this.toggleDeleteAllStandard,
      changeQuantityAdvanced: this.changeQuantityAdvanced,
      changeQuantityStandard: this.changeQuantityStandard,
      setWorkspace: this.setWorkspace,
      deleteAllSubs: this.deleteAllSubs,
      removeSubs: this.removeSubs,
      standard,
      advanced,
      handleChange: this.handleChange,
      addSubs: this.addSubs,
      loaded,
      show
    });
  }
}

User.defaultProps = {
  price: {
    standard: 12,
    advanced: 24
  },
  plan: {
    standard: 'standard',
    advanced: 'advanced'
  }
};

User.propTypes = {
  price: T.shape({
    standard: T.number.isRequired,
    advanced: T.number.isRequired
  }),
  plan: T.shape({
    standard: T.string.isRequired,
    advanced: T.string.isRequired
  })
};

const mapDispatchToProps = {
  getUserDetails: DuckGetUserDetails.triggers.get,
  getStripeData: DuckGetStripeData.triggers.get,
  getWorkspaces: DuckMainData.triggers.get,
  getPermissions: DuckPermissions.triggers.get
};

const mapStateToProps = state => {
  const user = state[DuckGetUserDetails.namespace];
  const stripe = state[DuckGetStripeData.namespace];
  const workspaces = state[DuckMainData.namespace];

  Logger.info('Workspaces', workspaces);

  return {
    user,
    stripe,
    workspaces
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);
