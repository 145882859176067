import namespace from '../../../../../redux/namespace';
import ns from './namespace';

const opened = `${namespace}${ns}/state/opened`;
const closed = `${namespace}${ns}/state/closed`;

const types = {
  opened,
  closed
};

export default types;
