import types from './types';

const selected = workspace => ({
  type: types.selected,
  payload: workspace
});

const actions = {
  selected
};

export default actions;
