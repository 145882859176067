import React from 'react';

const options = {
  place: 'tl',
  message: (
    <div>
      <div>Your advanced subscriptions have been successfully removed.</div>
    </div>
  ),
  type: 'success',
  icon: 'nc-icon nc-check-2',
  autoDismiss: 4,
  closeButton: true
};

export default options;
