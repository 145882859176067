import actions from './actions';
import triggers from './triggers';
import types from './types';
import namespace from './namespace';
import reducers from './reducers';
import initState from './state';

const handlers = {
  [types.opened]: reducers.open,
  [types.closed]: reducers.close
};

const reducer = (state = initState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

const duck = {
  namespace,
  triggers,
  reducer,
  default: initState
};

export default duck;
