import OAuth from '../../../../../../helpers/oauth';

async function params(workspace) {
  const token = await OAuth.token();
  return Promise.resolve({
    access_token: token,
    workspace // : 'T0CJ0AS8H', // channel.workspace.id,
    // channel // channel.id
  });
}

export default params;
