import Logger from '../../../../../helpers/logger';

class LambdaFunction {
  constructor(get = null, post = null) {
    this._get = get;
    this._post = post;
  }

  static initWithGet(get) {
    return new this(get);
  }

  static initWithPost(post) {
    return new this(null, post);
  }

  async get() {
    Logger.info('Starting Get Call');
    return this._get();
  }

  async post(params = null) {
    Logger.info('Starting post call');
    return this._post(params);
  }
}

export default LambdaFunction;
