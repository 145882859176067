import client from '../../client';
import params from './request-params';
import transformer from './response-transformer';

async function request({ dashboardId, workspaceIds }) {
  return params(dashboardId, workspaceIds)
    .then(parameters => client.post(parameters))
    .then(response => transformer(response));
}

export default request;
