import types from './types';
import Logger from '../../../../../../helpers/logger';

const success = (state, action) => {
  if (action.type !== types.success) {
    return state;
  }
  const { payload } = { ...action };

  Logger.info('Team Data Loaded');

  return {
    loaded: true,
    ...payload
  };
};

const fail = (state, action) => {
  if (action.type !== types.fail) {
    return state;
  }
  Logger.warn('Team data failed to load');
  return { loaded: false, failed: true, error: action.payload };
};

const reducers = {
  success,
  fail
};

export default reducers;
