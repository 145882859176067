import initState from './state';

const isDefault = workspaces =>
  workspaces.data.length === initState.data.length;

const selectors = {
  isDefault
};

export default selectors;
