import React from 'react';
import NotificationAlert from 'react-notification-alert';
import Sidebar from '../Sidebar';
import Routes from './routes';

class MoodbitSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: 'white',
      activeColor: 'primary'
    };
    // reference to be used globally
    window.sidebar = React.createRef();
  }

  render() {
    const state = { ...this.state };
    const bg = state.backgroundColor;
    const active = state.activeColor;

    return (
      <div>
        <NotificationAlert ref={window.notifyRef} />
        <Sidebar
          {...this.props}
          routes={Routes}
          bgColor={bg}
          activeColor={active}
          sidebarRef={window.sidebar}
        />
      </div>
    );
  }
}

export default MoodbitSidebar;
