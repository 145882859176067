import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import routes from './screens/index/routes';
import Logger from './helpers/logger';

const history = createBrowserHistory();

Logger.info(`Starting App`);
Logger.info(`BUILD NUMBER: ${process.env.REACT_APP_BUILD_NUMBER}`);

// Notification Alert Global Reference
// The component is stored in the MoodbitSidebar/index.jsx
const notifyRef = React.createRef();
window.notifyRef = notifyRef;

const App = () => (
  <Router history={history}>
    <Switch>
      {routes.map(item => (
        <div>
          <Route path={item.path} key={item.id} component={item.component} />
        </div>
      ))}
    </Switch>
  </Router>
);

export default App;
