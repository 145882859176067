import types from './types';

const success = data => ({ type: types.success, payload: data });
const fail = error => ({ type: types.fail, payload: error });

const actions = {
  success,
  fail
};

export default actions;
