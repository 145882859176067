import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import getNotifications from '../../common/domain/lambda/fn-moodbit-notifications';
import updateNotifications from '../../common/domain/lambda/fn-moodbit-notifications-updater';

import DuckMainData from '../../common/domain/lambda/fn-dashboard-main-data/ducks/get';
import DuckPermissions from '../../common/domain/lambda/fn-dashboard-get-user-permissions/ducks/get';
import RestrictedArea from '../restrictedarea';

import Logger from '../../helpers/logger';

import QuickSightAPI from '../../common/domain/lambda/fn-quicksight-url';
import View from './view';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        schedules: []
      },
      form: {},
      authorized: false,
      loaded: false
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  componentDidMount() {
    const { workspaces, getPermissions } = this.props;
    Logger.info('Available Workspaces', workspaces);
    const workspaceIds = workspaces.data.map(item => item.id);

    getPermissions();
    this.checkPermissions();
    this.getUrl();

    getNotifications
      .get({ workspaces: workspaceIds })
      .then(data => {
        Logger.debug(data);
        this.setState({ data });
      })
      .catch(() => {});
  }

  onChangeHandler({ name, uid, value, event, schedule }) {
    Logger.debug('Changed Event', { name, uid, value, event, schedule });
    const { form } = this.state;
    let data = form[uid];
    if (!data) {
      form[uid] = { uid };
      data = form[uid];
    }
    data[name] = value;
    Logger.debug('Schedule Form', data);
    this.setState({ form });
  }

  onSubmitHandler({ name, uid, event, form, team, schedule }) {
    Logger.info('Submit', name);
    if (form) {
      updateNotifications.get({ form, team, schedule }).then(response => {
        Logger.debug('Form Submitted', { name, uid, event, form });
        Logger.debug(response);
        this.setState({});
        // alert('Settings Saved');
        const success = {
          place: 'tl',
          message: 'Settings Saved',
          type: 'success',
          icon: 'nc-icon nc-alert-circle-i',
          autoDismiss: 7,
          closeButton: true
        };
        window.notifyRef.current.notificationAlert(success);
      });
    } else {
      // alert('Settings Saved');
      Logger.info('Not Saved', form);
    }
  }

  checkPermissions = () => {
    const { permissions } = this.props;
    Logger.info('Permissions', permissions);
    if (
      permissions &&
      permissions.loaded &&
      permissions.data &&
      permissions.data.notifications
    ) {
      Logger.info('User is authorized to this view');
      this.setState({ authorized: true });
    }
  };

  getUrl = () => {
    const { workspaces } = this.props;
    Logger.info('Available Workspaces', workspaces);
    const workspaceIds = workspaces.data.map(item => item.id);

    Logger.info('Sending', workspaceIds);

    this.setState({ loaded: false });

    QuickSightAPI.get({
      dashboardId: 'a0773a4c-82ac-4c8a-8ee3-9f57295ada1b',
      workspaceIds
    }).then(url => {
      Logger.info('Got Url', url);
      this.setState({ quicksight: url });
    });
  };

  hideLoadingSpinner = () => {
    Logger.info('Dashboard Iframe Loaded');
    this.setState({ loaded: true });
  };

  render() {
    const { data, form, loaded, quicksight, authorized } = this.state;
    const { permissions } = this.props;

    Logger.debug('Current Form', form);

    if (!loaded) {
      setTimeout(() => {
        this.hideLoadingSpinner();
      }, 5000);
    }

    const operations = {
      submit: this.onSubmitHandler,
      change: this.onChangeHandler
    };

    if (permissions.loaded && !authorized) {
      Logger.info('Not authorized!', permissions);
      return RestrictedArea();
    }

    const formView = View({ data, operations, form });
    return (
      <div>
        {formView}
        <Loader loaded={loaded} />
        <iframe
          title="quicksight"
          style={{ width: '100%', height: '700px', border: 'none' }}
          src={quicksight}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const workspaces = state[DuckMainData.namespace];
  const permissions = state[DuckPermissions.namespace];
  return { workspaces, permissions };
};

const mapDispatchToProps = {
  getWorkspaces: DuckMainData.triggers.get,
  getPermissions: DuckPermissions.triggers.get
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
