import types from './types';

const success = (state, action) => {
  if (action.type !== types.success) {
    return state;
  }
  const { payload } = { ...action };
  return {
    loaded: true,
    ...{
      name: payload.name,
      fullname: payload.fullname,
      email: payload.email
    }
  };
};

const fail = (state, action) => {
  if (action.type !== types.fail) {
    return state;
  }
  return { loaded: false, ...{ error: action.payload } };
};

const reducers = {
  success,
  fail
};

export default reducers;
