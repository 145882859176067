import params from './request-params';
import transformer from './response-transformer';
import client from '../../client';

async function request({ workspaces }) {
  return params(workspaces).then(data =>
    client.post(data).then(response => transformer({ response }))
  );
}

export default request;
