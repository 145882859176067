import token from '../../../common/params/access-token';

async function params(
  plan = 'standard',
  quantity = 0,
  deleteAll = false,
  item
) {
  return token().then(accessToken => {
    const form = {};
    const _token = JSON.parse(accessToken);

    form.access_token = _token.access_token;
    form.plan = plan;
    form.quantity = quantity;
    form.deleteAll = deleteAll;
    form.item = item;

    return Promise.resolve(form);
  });
}

export default params;
