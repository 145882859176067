import types from './types';
import Logger from '../../../../../helpers/logger';

const open = (state, action) => {
  if (action.type !== types.opened) {
    return state;
  }
  Logger.info('Got Open Reducer', action);
  return action.payload;
};

const close = (state, action) => {
  if (action.type !== types.closed) {
    return state;
  }
  Logger.info('Got Close Reducer', action);
  return action.payload;
};

const reducers = {
  open,
  close
};

export default reducers;
