import types from './types';

const open = () => ({
  type: types.opened,
  payload: { isClosed: false, state: 'opened' }
});

const close = () => ({
  type: types.closed,
  payload: { isClosed: true, state: 'closed' }
});

const actions = {
  open,
  close
};

export default actions;
