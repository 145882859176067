import types from './types';

const selected = (state, action) => {
  if (action.type !== types.selected) {
    return state;
  }
  return action.payload;
};

const reducers = {
  selected
};

export default reducers;
