import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

const RestrictedArea = () => (
  <Row>
    <Col xs={12}>
      <Card>
        <CardHeader>Restricted Area</CardHeader>
        <CardBody>
          <p>
            This area requires advanced subscription. Please go to subscriptions
            and adquire permissions to view this screen.
          </p>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default RestrictedArea;
