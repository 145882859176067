import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Nav } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import OAuth from '../../../helpers/oauth';

// javascript plugin used to create scrollbars on windows

import logo from '../../assets/img/moodbit.png';
import uid from '../../../helpers/uid';

let ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.sideBar = React.createRef();
    this.state = {
      latam: false
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sideBar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    const props = { ...this.props };
    return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  render() {
    const props = { ...this.props };
    const { bgColor, activeColor } = this.props;
    const { latam } = this.state;

    return (
      <div
        className="sidebar"
        data-color={bgColor}
        data-active-color={activeColor}
      >
        <div className="logo">
          <Link to="/" className="simple-text logo-mini">
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </Link>
          <Link to="/" className="simple-text logo-normal">
            Moodbit
          </Link>
        </div>
        <div className="sidebar-wrapper" ref={this.sideBar}>
          <Nav>
            {props.routes.map(prop => {
              if (prop.redirect) return null;
              if (prop.notInSidebar) return null;
              return (
                <li
                  className={
                    this.activeRoute(prop.path) +
                    (prop.pro ? ' active-pro' : '') +
                    (prop.sup ? ' active-sup' : '')
                  }
                  key={uid()}
                >
                  <NavLink
                    to={prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
            {/* {latam ? (
              <NavLink to="/quicksight" className="nav-link">
                <i className="nc-icon nc-globe" />
                <p>Latam</p>
              </NavLink>
            ) : null} */}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
