// index.js routes
import Main from '../layouts';

const routes = [
  {
    id: 'home',
    path: '/',
    name: 'Home',
    component: Main
  }
];

export default routes;
