import lambda from '../..';
import actions from './actions';
import Logger from '../../../../../../helpers/logger';

const get = () => dispatch => {
  dispatch(actions.loading());
  lambda
    .get()
    .then(result => {
      Logger.trace('Stripe Data obtained', result);
      dispatch(actions.success(result));
    })
    .catch(error => {
      Logger.trace('Error occurred while getting stripe data', error);
      dispatch(actions.fail(error));
    });
};

const success = (dispatch, data) => dispatch(actions.success(data));

const triggers = {
  get,
  success
};

export default triggers;
